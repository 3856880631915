<template>
  <div class="task">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="fromSocure">返回课程</el-breadcrumb-item>
      <el-breadcrumb-item>{{ testName }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="cont">
      <!-- 考试结果 汇总 -->
      <el-row :gutter="20" v-if="showTaskResult">
        <el-col :span="24">
          <el-card shadow="nerve" class="card">
            <div class="task-result">
<!--              <div class="icon-box">-->
<!--                &lt;!&ndash; <i class="el-icon-collection-tag"></i> &ndash;&gt;-->
<!--                <img-->
<!--                  v-if="isPass"-->
<!--                  class="img"-->
<!--                  src="https://www.wlmqcol.com/static-dist/app/img/item-bank/passed@2x.png?version=8.8.0"-->
<!--                />-->
<!--                <img-->
<!--                  v-else-->
<!--                  class="img"-->
<!--                  src="https://www.wlmqcol.com/static-dist/app/img/item-bank/unpassed@2x.png?version=8.8.0"-->
<!--                />-->
<!--              </div>-->
              <div class="content">
                <p v-if="isPass" class="tit">恭喜，您通过本次考试！</p>
                <p v-else class="tit">很遗憾，您未通过本次考试！</p>
                <!-- <p class="desc">教师评语 ：-</p> -->
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="18">
          <el-card shadow="nerve" class="card">
            <div v-if="!showTaskResult" class="header">
              <h3>{{ testName }}</h3>
              <span>答题中</span>
            </div>
            <!-- 考试结果成绩表格 -->
            <div v-else class="result-table">
              <div class="result-header">
                <div class="left-box">
                  <div class="header">
                    <h3>{{ testName }}</h3>
                    <span>批阅完成</span>
                  </div>
                  <div class="content">
                    答题人：{{ userName }}&nbsp;&nbsp;&nbsp;&nbsp;交卷时间：{{
                      submitTime
                    }}&nbsp;&nbsp;&nbsp;&nbsp;用时：{{ timeCost }}
                  </div>
                </div>
                <div class="right-box">
                  <div class="cont">
                    <p class="defen">{{ score.toFixed(1) }}</p>
                    <p class="zhongfen">总分：{{ totalScore }}</p>
                  </div>
                </div>
              </div>
              <p class="tit">单选题汇总：</p>
              <el-table
                :data="singleTableData"
                border
                style="width: 100%"
                :header-cell-style="{ background: '#f5f5f5' }"
              >
                <el-table-column prop="rightNum" label="正确数量">
                </el-table-column>
                <el-table-column prop="wrongNum" label="错误数量">
                </el-table-column>
                <el-table-column prop="blankNum" label="空白数量">
                </el-table-column>
                <el-table-column
                  prop="questionNum"
                  label="总数"
                ></el-table-column>
                <el-table-column prop="singleScore" label="分值">
                </el-table-column>
              </el-table>
              <p class="tit">多选题汇总：</p>
              <el-table
                :data="multipleTableData"
                border
                style="width: 100%"
                :header-cell-style="{ background: '#f5f5f5' }"
              >
                <el-table-column prop="rightNum" label="正确数量">
                </el-table-column>
                <el-table-column prop="wrongNum" label="错误数量">
                </el-table-column>
                <el-table-column prop="blankNum" label="空白数量">
                </el-table-column>
                <el-table-column
                  prop="questionNum"
                  label="总数"
                ></el-table-column>
                <el-table-column prop="singleScore" label="分值">
                </el-table-column>
              </el-table>
              <p class="tit">判断题汇总：</p>
              <el-table
                :data="judgmentTableData"
                border
                style="width: 100%"
                :header-cell-style="{ background: '#f5f5f5' }"
              >
                <el-table-column prop="rightNum" label="正确数量">
                </el-table-column>
                <el-table-column prop="wrongNum" label="错误数量">
                </el-table-column>
                <el-table-column prop="blankNum" label="空白数量">
                </el-table-column>
                <el-table-column
                  prop="questionNum"
                  label="总数"
                ></el-table-column>
                <el-table-column prop="singleScore" label="分值">
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { formatDuring } from '@/utils/index'

import {
  result,
} from '@/api/task'
export default {
  data() {
    return {
      testName: '', //  试题名称
      singleTableData: [], // 点选题考试结果
      multipleTableData: [], // 多选题考试结果
      judgmentTableData: [], // 判断题考试结果
      showTaskResult: false, // 是否显示考试结果
      timeCost: null, // 耗时 秒
      submitTime: null, // 交卷时间
      userName: null, // 答题人名称
      score: 0, //得分，
      totalScore: 0, // 总分

      singleScore: 0, // 单选题总分
      singleCount: 0, // 单选题数

      multipleScore: 0, // 多选题总分
      multipleCount: 0, // 多选题题数

      judgeScore: 0, // 判断题总分
      judgeCount: 0, // 判断题题数

      isPass: null // 是否通过考试
    }
  },
  mounted() {
    this.examId = this.$route.query.examId
    this.getTestResult()
  },
  methods: {
    // 获取考试结果
    getTestResult() {
      const obj = {
        examId: this.examId
      }
      result(obj).then((res) => {
        if (res.data.code === 0) {
          if (Object.keys(res.data.data).length === 0) {
            this.handleSubmit() // 数据为空先调一次submit接口返回正常数据
            //过期跳回上一页
            // this.$message({
            //   type: 'error',
            //   message: '未知异常，可以进行再次答卷！',
            //   duration: 3000
            //   /* onClose: () => {
            //     this.$router.go(-1);
            //   }, */
            // })
            this.$confirm(
              `很遗憾，发生未知错误，您可以再次答卷`,
              {
                confirmButtonText: '再次答卷',
                showCancelButton: false,
                // cancelButtonText: '取消',
                type: 'warning',
                showClose: false,
                closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
                closeOnPressEscape: false //是否可通过按下 ESC 键关闭 MessageBox
              }
            )
              .then(() => {
                
                this.handleReset() // 再次答卷
                /* this.$message({
                type: 'success',
                message: '删除成功!',
              }); */
              })
          } else {
            this.isPass = res.data.data.isPass
            this.singleTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.singleSummary.questionNum,
                rightNum: res.data.data.resultSummary.singleSummary.rightNum,
                wrongNum: res.data.data.resultSummary.singleSummary.wrongNum,
                blankNum: res.data.data.resultSummary.singleSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.singleSummary.singleScore
              }
            ]
            this.multipleTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.multipleSummary.questionNum,
                rightNum: res.data.data.resultSummary.multipleSummary.rightNum,
                wrongNum: res.data.data.resultSummary.multipleSummary.wrongNum,
                blankNum: res.data.data.resultSummary.multipleSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.multipleSummary.singleScore
              }
            ]
            this.judgmentTableData = [
              {
                questionNum:
                  res.data.data.resultSummary.judgmentSummary.questionNum,
                rightNum: res.data.data.resultSummary.judgmentSummary.rightNum,
                wrongNum: res.data.data.resultSummary.judgmentSummary.wrongNum,
                blankNum: res.data.data.resultSummary.judgmentSummary.blankNum,
                singleScore:
                  res.data.data.resultSummary.judgmentSummary.singleScore
              }
            ]
            // 绑定数据
            const { testPaperData } = res.data.data
            this.testName = res.data.data.exanName

            // 处理单选题
            this.singleChoiceQuestionsArr = testPaperData.singleChoiceQuestions
            this.singleChoiceQuestionsArr.forEach((item) => {
              Object.assign(item, {
                isChoose: true, // 是否已选择
                // isSign: false, // 是否已标记
                optionNo: 'b', //选项序号
                optionCode: 2, //选项编号
                disabled: true
              })
            })
            console.log(this.singleChoiceQuestionsArr)
            //处理多选题
            this.multipleChoiceQuestionsArr =
              testPaperData.multipleChoiceQuestions
            if (this.multipleChoiceQuestionsArr.length > 0) {
              this.multipleChoiceQuestionsArr.forEach((item) => {
                Object.assign(item, {
                  isChoose: true, // 是否已选择
                  // isSign: false, // 是否已标记
                  optionNo: '', //选项序号
                  optionCode: '', //选项编号
                  disabled: true
                })
                this.multipleChoiceQuestions.push([]) // 绑定相应的el-checkbox-group  的v-model
              })
            }

            // 处理判断题
            this.judgmentQuestionsArr = testPaperData.judgmentQuestions
            this.judgmentQuestionsArr.forEach((item) => {
              Object.assign(item, {
                isChoose: true, // 是否已选择
                // isSign: false, // 是否已标记
                optionNo: '', //选项序号
                optionCode: '', //选项编号
                disabled: true
              })
            })
            console.log(this.singleChoiceQuestionsArr.length)
            console.log(this.multipleChoiceQuestionsArr.length)
            console.log(this.judgmentQuestionsArr.length)

            this.userName = res.data.data.userName
            this.submitTime = res.data.data.submitTime
            this.timeCost = formatDuring(res.data.data.timeCost)
            this.score = res.data.data.score
            this.totalScore = res.data.data.totalScore
          }
        }
      })
    },
  }
}
</script>

<style>

</style>